import React from 'react';
import * as s from './s.module.css';
import cx from 'classnames';
import { Link } from 'gatsby'
import { graphql, StaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import config from '../../../config';

interface Props {
    location: any,
}

class Vpp extends React.Component<Props, {}> {
    render() {
        return (
            <StaticQuery
                query={graphql`
                query {
                    allFile {
                        edges {
                            node {
                                name
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: FULL_WIDTH
                                    )
                                }
                            }
                        }
                    }
                }
            `}
                render={imagesData => (
                    <section className={s.vppContainer}>
                        <div className={s.vppContent}>
                            <p className={cx(s.title, s.bold)}>The VPP token can be used: </p>
                            <div className={s.tableContainer}>
                                <div className={s.myTableList}>
                                    <div className={s.row}>
                                        <div className={s.leftColumn}><p className={s.blue}>1.</p></div>
                                        <div>
                                            <p>
                                                <span className={cx(s.subtitle, s.bold)}>Staked by Players (Real-Money Only): </span> Earn bonus chips each week to boost $GOLD earnings.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div className={s.leftColumn}><p className={s.blue}>2.</p></div>
                                        <div>
                                            <p>
                                                <span className={cx(s.subtitle, s.bold)}>Staked to become a Justice: </span> Justices are non-playing peers who are assigned to tables but don’t receive cards and ensure the security of the table games. Justices in return receive $GOLD and $ETH rewards based on the volume of play on the platform
                                            </p>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div className={s.leftColumn}><p className={s.blue}>3.</p></div>
                                        <div>
                                            <p>
                                                <span className={cx(s.subtitle, s.bold)}>Liquidity Provider: </span> Purchasers of VPP can become a Liquidity Provider on either PancakeSwap or Uniswap.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className={cx(s.title, s.bold)}>How to Buy </p>
                            <p className={cx(s.mt_15)}>You can currently purchase Virtue Player Points from the following markets:</p>
                            <div className={s.buttonTokenContainer}>
                                <a target='_blank' className={cx(s.buttonToken)} href={config.externalLinks.uniswap}>
                                    <GatsbyImage className={cx(s.buttonImg)} image={imagesData.allFile.edges.find(edge => !!edge.node && edge.node.name === '2x-btn-uniswap').node.childImageSharp.gatsbyImageData} alt='image' />
                                </a>
                                <a target='_blank' className={cx(s.buttonToken)} href={config.externalLinks.pancake2}>

                                    <GatsbyImage className={cx(s.buttonImg)} image={imagesData.allFile.edges.find(edge => !!edge.node && edge.node.name === '2x-btn-pancake').node.childImageSharp.gatsbyImageData} alt='image' />
                                    <span className={s.tokenomics}>Tokenomics</span>
                                </a>
                                <a target='_blank' className={cx(s.buttonToken)} href={config.externalLinks.mexc}>
                                    <GatsbyImage className={cx(s.buttonImg)} image={imagesData.allFile.edges.find(edge => !!edge.node && edge.node.name === '2x-btn-mexc').node.childImageSharp.gatsbyImageData} alt='image' />
                                </a>
                                <a target='_blank' className={cx(s.buttonToken)}>
                                    <GatsbyImage className={cx(s.buttonImg)} image={imagesData.allFile.edges.find(edge => !!edge.node && edge.node.name === '2x-btn-cex').node.childImageSharp.gatsbyImageData} alt='image' />
                                    <span className={s.commingSoon}>(coming soon)</span>
                                </a>
                            </div>
                            <a target='_blank' className={cx(s.title, s.tokenomicsLink, s.bold)} href={`${config.webAssetsBaseUrl}/spreadsheet/VPP_Tokenomics/VPP_Tokenomics.html`}>Tokenomics</a>
                            <div className={s.tokenomicsContainer}>
                                <Link target='_blank' className={cx(s.tokenomicsLinks)} to='/promotions'>
                                    <GatsbyImage className={cx(s.tokenomicsImg)} image={imagesData.allFile.edges.find(edge => !!edge.node && edge.node.name === '2x-ico-btn').node.childImageSharp.gatsbyImageData} alt='image' />
                                    <span className={s.tokenomicsSpan1}>Earn Bonus Chips</span>
                                </Link>
                                <Link target='_blank' className={cx(s.tokenomicsLinks)} to='/justice'>
                                    <GatsbyImage className={cx(s.tokenomicsImg)} image={imagesData.allFile.edges.find(edge => !!edge.node && edge.node.name === '2x-ico-btn').node.childImageSharp.gatsbyImageData} alt='image' />
                                    <span className={s.tokenomicsSpan2}>Become a Justice</span>
                                </Link>
                                <a target='_blank' className={cx(s.tokenomicsLinks)} href='https://app.uniswap.org/#/add/ETH/0x5EeAA2DCb23056F4E8654a349E57eBE5e76b5e6e/10000?chain=mainnet'>
                                    <GatsbyImage className={cx(s.tokenomicsImg)} image={imagesData.allFile.edges.find(edge => !!edge.node && edge.node.name === '2x-ico-btn').node.childImageSharp.gatsbyImageData} alt='image' />
                                    <span className={s.tokenomicsSpan3}>Become a Liquidity Provider</span>
                                </a>
                            </div>
                            <p className={cx(s.title, s.bold)}>Stats </p>
                            <div className={s.tableContainer}>
                                <div className={cx(s.roundUpper, s.myTable)}>
                                    <div className={cx(s.roundUpper, s.rowHeader)}>
                                        <div className={s.w_33}>
                                            <p>VPP Holders (Ethereum)</p>
                                        </div>
                                        <div className={s.separator} />
                                        <div className={s.w_33}>
                                            <p>VPP Holders (BSC)</p>
                                        </div>
                                        <div className={s.separator} />
                                        <div className={s.w_33}>
                                            <p>Volume Traded</p>
                                        </div>
                                    </div>
                                    <div className={s.row}>
                                        <div className={cx(s.roundDownLeft, s.w_33)}>
                                            <p>12,000</p>
                                        </div>
                                        <div className={s.w_33}>
                                            <p>5,900</p>
                                        </div>
                                        <div className={cx(s.roundDownRight, s.w_33)}>
                                            <p>&gt;$100,000,000+</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            />
        );
    }
}

export default Vpp; 
