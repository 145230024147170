// extracted by mini-css-extract-plugin
export var blue = "s-module--blue--adfe4";
export var bold = "s-module--bold--0a17a";
export var buttonImg = "s-module--buttonImg--3b02a";
export var buttonToken = "s-module--buttonToken--bfccc";
export var buttonTokenContainer = "s-module--buttonTokenContainer--dff86";
export var commingSoon = "s-module--commingSoon--83812";
export var contentAnimation = "s-module--contentAnimation--1828c";
export var leftColumn = "s-module--leftColumn--9d7d1";
export var mt_15 = "s-module--mt_15--af028";
export var myTable = "s-module--myTable--f4bf5";
export var myTableList = "s-module--myTableList--0a439";
export var roundDownLeft = "s-module--roundDownLeft--3cc99";
export var roundDownRight = "s-module--roundDownRight--a58ab";
export var roundUpper = "s-module--roundUpper--869a9";
export var row = "s-module--row--785d0";
export var rowHeader = "s-module--rowHeader--a8cc5";
export var separator = "s-module--separator--8ff82";
export var subtitle = "s-module--subtitle--ce0b9";
export var tableContainer = "s-module--tableContainer--61ff3";
export var title = "s-module--title--24b89";
export var tokenomics = "s-module--tokenomics--ee5b8";
export var tokenomicsContainer = "s-module--tokenomicsContainer--95ff1";
export var tokenomicsImg = "s-module--tokenomicsImg--8af1b";
export var tokenomicsLink = "s-module--tokenomicsLink--d5897";
export var tokenomicsLinks = "s-module--tokenomicsLinks--3b05b";
export var tokenomicsSpan1 = "s-module--tokenomicsSpan1--7cab1";
export var tokenomicsSpan2 = "s-module--tokenomicsSpan2--c8c47";
export var tokenomicsSpan3 = "s-module--tokenomicsSpan3--1abe6";
export var vppContainer = "s-module--vppContainer--bff06";
export var vppContent = "s-module--vppContent--060cb";
export var w_33 = "s-module--w_33--10c15";