import React from "react"

import Layout from "../../layout/default"
import Seo from "../../components/seo"
import VppTokenPage from '../../components/TokenEconomy/vppTokenPage'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="VPP Token" />
    <VppTokenPage location={location}/>
  </Layout>
)

export default IndexPage
